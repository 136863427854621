import React, { useRef } from 'react'
import { Box, Flex, Input, Select, Text, Image, IconButton, Button } from 'theme-ui'
import { useEditor, useNode, useNodeReturnType } from '@craftjs/core'
import { FaGripVertical, FaRegTrashAlt } from 'react-icons/fa'
import colors from '../../gatsby-plugin-theme-ui/theme/colors'
import ahoy from '../../utils/ahoy/ahoy';


interface LinkProps {
  id?: number | string
  url: string
  type:
    | 'tokopedia'
    | 'shopee'
    | 'bukalapak'
    | 'lazada'
    | 'whatsapp'
    | 'line'
    | 'url'
    | 'upload'
  variant?: string
  name?: string
  icon?: string /**@todo implement icon, and upload icon */
}

function Link({ id, icon, name, url }: LinkProps) {
  const { enabled } = useEditor((state) => {
    return {
      enabled: state.options.enabled,
    }
  });

  const {
    connectors: { connect, drag },
  } = useNode<LinkProps>()

  return (
    <Box
      as="a"
      key={id}
      px={2}
      py={3}
      m={2}
      //@ts-ignore
      href={url}
      target="__blank"
      sx={{
        display: 'block',
        border: '1px solid var(--theme-ui-colors-primary)',
        textDecoration: 'none',
        color: 'primary',
        textAlign: 'center',
        position: 'relative',
        borderRadius: 10
      }}
      onClick={() => {
        // Only track events when the editor is not in enabled mode
        // Editor is not enabled when presented to the end user.
        // We need to do this otherwise clicking on the component while the user is editing the page
        // will still trigger an event which will results in inflated count of events.
        if (!enabled) {
          ahoy.default.track(
            'click_shop_url',
            {
              name,
              link_url: url,
            }
          )
        }
      }}
    >
      {icon && (
        <Image
          src={icon}
          width="30px"
          height="30px"
          sx={{ position: 'absolute', left: 3, top: '10px' }}
        />
      )}
      {name}
    </Box>
  )
}

function LinkSettings({
  isEdit,
}: {
  link: LinkProps
  setProp: useNodeReturnType<any>['setProp']
  isEdit: boolean
}) {
  const {
    type, url, name, icon,
    actions: {
      setProp,
    },
  } = useNode<LinkProps>((node) => ({
    type: node.data.props.type,
    url: node.data.props.url,
    name: node.data.props.name,
    icon: node.data.props.icon,
  }))
  const fileInputRef = useRef(null);
  const isUpload = type === 'upload';

  function update(fields: Partial<LinkProps>) {
    setProp((props: LinkProps) => {
      Object.keys(fields).forEach(key => {
        props[key] = fields[key];
      })
    })
  }

  function handleSelectType(e) {
    const type = e.target.value
    const icon = iconMap[type]
    update({ type: e.target.value, icon })
  }

  function handleChangeInput(e) {
    const newFile = URL.createObjectURL(e.target.files[0]);
    setProp((props) => (props.src = newFile));
    update({ type: type, icon: newFile })
  }

  function handleClickUpload(e) {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current.click();
  }

  function handleDeleteImg() {
    setProp((props) => props.src = null )
    update({ type: type, icon: '' })
  }

  return (
    <Box
      sx={{
        border: '1px solid var(--theme-ui-colors-muted)',
        borderRadius: 4,
        mb: 3,
        px: 2,
        py: 3,
        boxShadow: isEdit ? '-2px 4px 10px -5px grey' : 'unset',
      }}
    >
      <Box py={2}>
        <Input
          placeholder="Display Name"
          bg="white"
          mb={2}
          value={name}
          onChange={(e) => update({ name: e.target.value })}
        />
        <Input
          placeholder="Enter URL"
          bg="white"
          mb={2}
          value={url}
          onChange={(e) => update({ url: e.target.value })}
        />
        <Flex>
          <Select
            placeholder="Type"
            value={type}
            mb={2}
            onChange={handleSelectType}
          >
            <option value=""></option>
            <option value="tokopedia">Tokopedia</option>
            <option value="shopee">Shopee</option>
            <option value="bukalapak">Bukalapak</option>
            <option value="lazada">Lazada</option>
            <option value="whatsapp">Whatsapp</option>
            <option value="line">Line</option>
            <option value="url">URL</option>
            <option value="upload">Upload</option>
          </Select>
        </Flex>
        {isUpload && (
          <Box>
            <Box
              sx={{
                borderRadius: "m",
                borderColor: "gray0",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <Flex
                py={2}
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "15px",
                  textAlign: "center",
                  height: "250px",
                }}
              >
                <Input
                  type="file"
                  ref={fileInputRef}
                  accept="image/png, image/jpg, image/gif"
                  sx={{ display: "none" }}
                  onChange={handleChangeInput}
                />

                {isUpload && icon ? (
                  <Image
                    src={icon}
                    alt="uploaded-image"
                    sx={{ maxWidth: "350px", height: "240px" }}
                  />
                ) : (
                  <>
                    <Text sx={{ pb: "15px" }}>
                      Unggah media jpg, png, atau gif ke sini
                    </Text>

                    <Button variant="outline" onClick={handleClickUpload}>
                      Unggah media
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
            <Box py="15px">
              {icon && (
                <Flex mb="15px">
                  <Button
                    variant="outline"
                    sx={{ width: "100%", mr: "5px" }}
                    onClick={handleClickUpload}
                  >
                    Ubah
                  </Button>
                  <Button
                    variant="outline"
                    sx={{ width: "100%", ml: "5px" }}
                    onClick={handleDeleteImg}
                  >
                    Hapus
                  </Button>
                </Flex>
              )}
            </Box>
          </Box>
        )}
        {!isUpload && (
          <Input
            placeholder="Enter Icon URL"
            bg="white"
            mb={2}
            value={icon}
            onChange={(e) => update({ icon: e.target.value })}
          />
        )}
      </Box>
    </Box>
  )
}

function LinkLayer() {
  const {
    name,
    actions: {
      setProp,
    },
    id: nodeId,
  } = useNode((node) => ({
    name: node.data.props.name,
  }))

  const {
    actions,
    connectors: {
      hover,
      select,
    },
  } = useEditor((state) => {
    return {
      selectedNodeId: state.events.selected,
      settingsComponent:
        state.nodes[state.events.selected] &&
        state.nodes[state.events.selected].related.settings,
    }
  })

  return (
    <Flex
      sx={{
        borderRadius: 'm',
        borderStyle: 'solid',
        borderColor: 'gray0',
        borderWidth: 1,
      }}
    >
      <Flex
        sx={{
          flex: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box padding={'7px'} sx={{ flex: 1 }}>
          <IconButton onClick={() => actions.delete(nodeId)} sx={{ cursor: 'pointer' }}>
            <FaRegTrashAlt size={18} color={colors.secondary} />
          </IconButton>
        </Box>
        <Box
          sx={{ flex: 5, textAlign: 'center', cursor: 'pointer' }}
          ref={ref => select(ref, nodeId)}
        >
          <Text color="primary">{name}</Text>
        </Box>
      </Flex>
      <Box ref={ref => hover(ref, nodeId)} padding={'7px'} sx={{ flex: 1, textAlign: 'right' }}>
        <IconButton sx={{ cursor: 'move' }}>
          <FaGripVertical size={18} color={colors.secondary} />
        </IconButton>
        </Box>
    </Flex>
  )
}

Link.craft = {
  name: 'Link',
  related: {
    settings: LinkSettings,
    layer: LinkLayer,
  },
}

export default Link

/**
 * @todo use proper icons src
 */

const iconMap = {
  tokopedia:
    'https://www.freepnglogos.com/uploads/logo-tokopedia-png/berita-tokopedia-info-berita-terbaru-tokopedia-6.png',
  shopee:
    'https://www.freepnglogos.com/uploads/shopee-logo/shopee-circle-logo-design-shopping-bag-13.png',
  bukalapak:
    'https://www.freepnglogos.com/uploads/bukalapak-logo-png/identitas-brand-bukalapak-bukalapak-5.png',
  lazada:
    'https://4.bp.blogspot.com/-d7ejX_qMOTI/XgrnnlT-VwI/AAAAAAAABPw/GtwyGgILiF8aWtCaYoKEKokgsokXi4rIQCLcBGAsYHQ/s1600/lazada.png',
  whatsapp:
    'https://w7.pngwing.com/pngs/672/164/png-transparent-whatsapp-icon-whatsapp-logo-computer-icons-zubees-halal-foods-whatsapp-text-circle-unified-payments-interface.png',
  line: 'https://www.pngfind.com/pngs/m/109-1093551_line-chat-icon-logo-line-messenger-logo-vector.png',
  url: 'https://cdn2.iconfinder.com/data/icons/pittogrammi/142/95-512.png',
  upload: '',
}
