import React from "react";
import { Box, Button, Flex, Image, Link, Text } from "@theme-ui/components";
import { CurrencyFormat, PercentageFormat } from "../Editor/CurrencyFormat";

const PromoComp = ({
  platform = "other",
  storeName = "Store name",
  url = null,
  voucherAmount = "Nominal",
  voucherName = "Voucher name",
  voucherType = "amount",
}: {
  platform: string,
  storeName: string,
  url: string,
  voucherAmount: string,
  voucherName: string,
  voucherType: string
}) => {

  const bgImage =
    platform === "shopee"
      ? "https://www.desty.app/assets/VoucherBG-shopee.d62ad894.svg"
      : platform === "tokopedia"
      ? "https://www.desty.app/assets/VoucherBG-toko.06d88023.svg"
      : "https://www.desty.app/assets/VoucherBG-other.940c4739.svg";
  const iconImg =
    platform === "shopee"
      ? "https://www.freepnglogos.com/uploads/shopee-logo-png/shopee-logo-shop-with-the-gentlemen-collection-and-win-the-shopee-0.png"
      : platform === "tokopedia"
      ? "https://www.freepnglogos.com/uploads/logo-tokopedia-png/berita-tokopedia-info-berita-terbaru-tokopedia-6.png"
      : "https://www.desty.app/assets/iconOther.4c99d489.svg";

  return (
    <Flex
      sx={{ padding: "24px", alignItems: "center", justifyContent: "center" }}
    >
      <Flex
        sx={{
          borderRadius: "m",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "1px 2px 23px -6px rgb(0 0 0 / 86%)",
        }}
      >
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <Box
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: "no-repeat",
              height: "86px",
              overflow: "hidden",
              backgroundSize: "cover",
              width: "272px",
            }}
          >
            <Box
              sx={{
                fontSize: "12px",
                margin: "14px",
                padding: 0,
                lineHeight: "16px",
                color: "white",
              }}
            >
              {voucherName}
            </Box>
            <Flex
              sx={{ mt: "4px", mx: "16px", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  flex: 2,
                  maxWidth: "159px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  m: 0,
                  p: 0,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  color: "yellow",
                }}
              >
                {voucherAmount === 'Nominal' ? (
                  <Text>{voucherAmount}</Text>
                ) : (
                  <>
                  {voucherType === 'amount' ? (
                    <CurrencyFormat value={voucherAmount} />
                  ) : (
                    <PercentageFormat value={voucherAmount} />
                  )}
                  </>
                )}
              </Box>
              <Box sx={{ flex: 1 }}>
                <Link href={url} target="_blank">
                  <Button variant="forPromo">Check</Button>
                </Link>
              </Box>
            </Flex>
          </Box>
          <Flex
            sx={{
              alignItems: "center",
              borderRadius: "0 0 8px 8px",
              height: "40px",
              overflow: "hidden",
              background: "white",
            }}
          >
            <Image
              src={iconImg}
              sx={{ width: "24px", height: "24px", mx: "12px" }}
            />
            <Box
              sx={{
                width: "189px",
                fontSize: "12px",
                lineHeight: "120%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                m: 0,
              }}
            >
              {storeName}
            </Box>
          </Flex>
          <Flex
            sx={{
              position: "absolute",
              bottom: "40px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
            <Box sx={{ width: "11px", height: "5px", backgroundColor: "white", borderRadius: "10px 10px 0 0", borderBottom: "none" }} />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PromoComp;
