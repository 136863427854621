import React, { useState } from "react";
import { useEditor, useNode } from "@craftjs/core";
import { FaGripVertical, FaRegTrashAlt } from "react-icons/fa";
import { Box, Flex, Switch, Text } from "@theme-ui/components";

import { InputComponent } from "../Editor/Input";
import ProductComp from "../landingPage/ProductComp";
import { useMutation } from "react-query";
import api from "../../utils/api";
import colors from '../../gatsby-plugin-theme-ui/theme/colors'
import { IconButton } from "theme-ui";

const Product = ({
  image,
  price,
  showPrice,
  showLogo,
  title,
  type,
}) => {
  const {
    connectors: { connect, drag },
    id: nodeId,
  } = useNode((node) => ({
    isActive: node.events.selected,
  }));

  return (
    <div ref={(ref) => connect(drag(ref))}>
      <ProductComp
        key={nodeId}
        showPrice={showPrice}
        showLogo={showLogo}
        title={title}
        price={price}
        image={image}
        type={type}
      />
    </div>
  );
};

const ProductSettings = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const {
    actions: { setProp },
    productUrl,
    showLogo,
    showPrice,
    id: nodeId,
  } = useNode((node) => ({
    showLogo: node.data.props.showLogo,
    showPrice: node.data.props.showPrice,
    productUrl: node.data.props.productUrl,
  }));

  const { mutate } = useMutation((values: {targetUrl: string, siteName: string}) => api.crawlMetadata(values), {
    onSuccess: ({ data }) => {

      if (!!data) {
        setProp((props) => {
          props.image = data.image;
          props.price = data.amount || data.price;
          props.title = data.title;
          props.type = data.site_name;
        })
      } else {
        setProp((props) => {
          props.image = 'https://www.freeiconspng.com/uploads/no-image-icon-6.png';
          props.price = '';
          props.title = '';
          props.type = '';
        })
      }
    },
  })

  function getData( value) {
    const isShopee = value.includes('https://shopee.co.id');
    const isTokped = value.includes('https://www.tokopedia.com');
    
    const siteName = value.includes("shopee") ? "shopee" : "tokopedia"
    const values = {
      targetUrl: value,
      siteName
    }

    if (!isShopee && !isTokped && value) {
      setErrorMessage('URL yang anda masukan tidak valid')
    } else {
      setErrorMessage('');
    }

    setProp((props) => {
      props.productUrl = value;
    });

    mutate(values);
  }

  function onShowLogoChanged(e) {
    setProp((props) => {
      props.showLogo = !props.showLogo;
    })
  }

  async function onShowPriceChanged(e) {
    setProp((props) => {
      props.showPrice = !props.showPrice;
    })
  }

  return (
    <Box pb={1}>
      <InputComponent
        value={productUrl}
        placeholder="Masukkan URL produk"
        label="Link produk"
        onChange={(e) => getData(e.target.value)}
      />
      <Flex sx={{ flexDirection: 'column' }}>
        <Text my={2} sx={{ fontSize: "10px" }}>
          Saat ini hanya bisa menampilkan produk dari Shopee dan
          Tokopedia.
        </Text>
        <Text sx={{ fontSize: "10px" }}>
          Contoh URL: https://shopee.co.id/product/567401590/13431378674 atau https://www.tokopedia.com/warriorsbasket/sepatu-basket-kyrie-7-high-icon-of-sport-43
        </Text>
        {errorMessage && (
          <Text my={1} color="error" sx={{ fontSize: "12px" }}>
            {errorMessage}
          </Text>
        )}
      </Flex>
      <Flex
        sx={{
          mt: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Switch label="Tampilkan logo" onChange={onShowLogoChanged} value={showLogo} checked={showLogo} key={nodeId} />
      </Flex>
      <Flex mt={2}>
        <Switch label="Tampilkan harga" onChange={onShowPriceChanged} value={showPrice} checked={showPrice} key={nodeId} />
      </Flex>
    </Box>
  );
};

function ProductLayer() {
  const {
    title,
    id: nodeId,
  } = useNode((node) => ({
    title: node.data.props.title,
  }))

  const {
    actions,
    connectors: {
      hover,
      select,
    },
  } = useEditor((state) => {
    return {
      selectedNodeId: state.events.selected,
      settingsComponent:
        state.nodes[state.events.selected] &&
        state.nodes[state.events.selected].related.settings,
    }
  })

  return (
    <Flex
      sx={{
        borderRadius: 'm',
        borderStyle: 'solid',
        borderColor: 'gray0',
        borderWidth: 1,
      }}
    >
      <Flex
        sx={{
          flex: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box padding={'7px'} sx={{ flex: 1 }}>
          <IconButton onClick={() => actions.delete(nodeId)} sx={{ cursor: 'pointer' }}>
            <FaRegTrashAlt size={18} color={colors.secondary} />
          </IconButton>
        </Box>
        <Box
          sx={{ flex: 5, textAlign: 'center', cursor: 'pointer' }}
          ref={ref => select(ref, nodeId)}
        >
          <Text color="primary">{title || 'Produk Anda'}</Text>
        </Box>
      </Flex>
      <Box ref={ref => hover(ref, nodeId)} padding={'7px'} sx={{ flex: 1, textAlign: 'right' }}>
        <IconButton sx={{ cursor: 'move' }}>
          <FaGripVertical size={18} color={colors.secondary} />
        </IconButton>
        </Box>
    </Flex>
  )
}

Product.craft = {
  name: 'Produk',
  related: {
    settings: ProductSettings,
    layer: ProductLayer,
  },
};

export default Product;
