// components/user/Text.js
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { useNode } from '@craftjs/core'
import SharedLayer from '../Editor/SharedLayer'

// Gatsby build breaks the usual import hence the special import using require syntax
const ReactQuill = typeof window !== `undefined` ? require('react-quill') : null;


const Text = ({ text }) => {
  const {
    connectors: { connect, drag },
    isActive,
  } = useNode((node) => ({
    isActive: node.events.selected,
  }))

  return (
    <div
      ref={(ref) => connect(drag(ref))}
      className='ql-editor'
      dangerouslySetInnerHTML={{ __html: text }}
      style={{
        overflowY: 'visible',
      }}
    ></div>
  )
}

const TextSettings = () => {
  const {
    actions: { setProp },
    text,
  } = useNode((node) => ({
    text: node.data.props.text,
    parentNode: node.data.parent,
  }))

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 7] }],
      ['bold', 'underline', 'italic'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }

  return (
    <>
      <div>
        <ReactQuill
          modules={modules}
          theme="snow"
          defaultValue={text}
          onChange={(event) => setProp((props) => (props.text = event))}
          style={{ height: '200px', marginBottom: '25px' }}
        />
      </div>
    </>
  )
}

Text.craft = {
  name: 'Teks',
  related: {
    settings: TextSettings,
    layer: SharedLayer,
  },
}

export default Text
