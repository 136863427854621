import React from 'react'
import { Box, ImageProps, Link, Image } from "@theme-ui/components"

interface ImageComp extends ImageProps {
  src: string
  isLink?: boolean
  url?: string | null
  shape: string | "square" | "circle"
  size: string | "XS" | "S" | "M" | "L" | "XL"
}

const ImageComp = ({
  isLink = false,
  shape = 'square',
  size = 'M',
  src,
  url = "",
}) => {
  const width = size === 'XS' ? '25%' : size === 'S' ? '45%' : size === 'M' ? '60%' : size === 'L' ? '85%' : size === 'XL' ? '100%' : ''

  return (
    <Box sx={{ boxSizing: 'border-box', position: 'relative', cursor: 'pointer' }}>
      <Box sx={{ zIndex: 0, display: 'inline-block', width: '100%', overflow: 'hidden', position: 'relative', textAlign: 'center', padding: '15px 0px' }}>
        <Link sx={{ textDecoration: 'none' }} href={isLink ? url : ''} target={isLink ? "_blank" : ''}>
          {shape === 'square' ? (
            <Image
              src={isLink ? url : src}
              sx={{
                margin: '0px auto',
                zIndex: 0,
                width: width
              }}
            />
          ) : (
            <Box sx={{ width: width, margin: '0px auto' }}>
              <Box sx={{
                backgroundImage: `url(${isLink ? url : src})`,
                zIndex: 0,
                display: 'inline-block',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                margin: '0px auto',
                width: '50%',
                height: 0,
                paddingBottom: '50%',
                fontSize: 0,
                borderRadius: '50%',
                overflow: 'hidden',
                position: 'relative',
              }}>
              </Box>
            </Box>
          )}
        </Link>
      </Box>
    </Box>
  )
}

export default ImageComp