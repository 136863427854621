import React from 'react';
import lz from "lzutf8";
import { useEditor, useNode } from '@craftjs/core';
import { Box, Card, Flex, Text, } from 'theme-ui';
import { FaCopy, FaPercent } from 'react-icons/fa';
import { MdImage, MdLink, MdOutlineDragIndicator, MdShoppingBasket, MdStars, MdTitle, MdVerticalDistribute, MdVideocam } from 'react-icons/md'


const SharedLayer: React.FC = () => {
  const { displayName, nodeId, parentNode } = useNode((node) => ({
    displayName: node.data.displayName,
    nodeId: node.id,
    parentNode: node.data.parent,
  }));

  const {
    actions,
    connectors: {
      hover, select
    },
    query: {
      createNode,
      node,
      serialize,
    },
  } = useEditor();
  
  return (
    <Card variant='sheet'>
      <Flex style={{ alignItems: 'center' }}>
        <Flex
          ref={ref => select(ref, nodeId)}
          sx={{
            flex: 6,
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: 1 }}>
            {iconMapping[displayName]}
          </Box>
          <Box sx={{ flex: 4 }}>
            <Text sx={{ fontSize: '15px' }}>
              {displayName}
            </Text>
          </Box>
        </Flex>
        <Box onClick={(e) => {
              const { data: {type, props}} = node(nodeId).get();
              actions.add(
                createNode(React.createElement(type, props)),
                parentNode,
                node(parentNode).get().data.nodes[nodeId],
              );
              // TODO: Delete this, this is just to test that serialization works
              console.log(
                lz.encodeBase64(lz.compress(serialize()))
              );
            }} sx={{ flex: 1, textAlign: 'center' }}>
            <FaCopy size={25} />
          {/* <Button
            p={0}
            mr={1}
            // bg="transparent"
            onClick={(e) => {
              const { data: {type, props}} = node(nodeId).get();
              actions.add(
                createNode(React.createElement(type, props)),
                parentNode,
                node(parentNode).get().data.nodes[nodeId],
              );
              // TODO: Delete this, this is just to test that serialization works
              console.log(
                lz.encodeBase64(lz.compress(serialize()))
              );
            }}
          >
            <FaCopy size={20} />
          </Button> */}
        </Box>
        <Box ref={ref => hover(ref, nodeId)} sx={{ flex: 1, textAlign: 'center' }}>
          <MdOutlineDragIndicator size={25} />

          {/* <Button
            p={0}
            // bg="transparent"
            sx={{ display: 'inline', cursor: 'all-scroll' }}
          >
            <MdOutlineDragIndicator />
          </Button> */}
        </Box>
      </Flex>
    </Card>
    
  );
};

export default SharedLayer;

const iconMapping = {
  'Divider': <MdVerticalDistribute size={35} />,
  'Image': <MdImage size={35} />,
  'Katalog Link': <MdLink size={35} />,
  'Katalog Produk': <MdShoppingBasket size={35} />,
  'Promo': <FaPercent size={35} />,
  'Katalog Ikon Sosial': <MdStars size={35} />,
  'Teks': <MdTitle size={35} />,
  'Video': <MdVideocam size={35} />,
}