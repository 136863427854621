// import { lighten } from '@theme-ui/color'
import React, { ReactNode, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { Box, Label, Input, Flex, InputProps, Text } from 'theme-ui'
import IconButton from './IconButton'

export interface InputComponentProps extends InputProps {
  label?: string
  placeholder?: string
  row?: boolean
  icon?: ReactNode
  inputWidth?: string | number
  containerWidth?: string | number
  isInvalid?: string | boolean
  errorMessage?: string
  isPassword?: boolean
}

export const InputComponent = (props: InputComponentProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const borderStyle = {
    borderRadius: 's',
    border: '1px solid',
    borderColor: props.isInvalid || props.errorMessage ? 'error' : 'primary',
    bg: props.isInvalid || props.errorMessage ? 'error' : 'unset',
  }

  return (
    <Box sx={{ width: props.containerWidth }}>
      {props.row ? (
        <Flex sx={{ alignItems: 'baseline' }}>
          {props.label && (
            <Box sx={{ flex: 1 }}>
              <Label sx={{ mb: '3' }}>{props.label}</Label>
            </Box>
          )}
          <Box sx={{ flex: 5, flexDirection: 'column', textAlign: 'left' }}>
            <Input
              sx={{ width: props.inputWidth, ...borderStyle }}
              // variant={props.isInvalid && 'error' || 'input'}
              placeholder={props.placeholder}
              onChange={props.onChange}
              disabled={props.disabled}
              ref={props.ref}
              {...props}
            />
            {props.errorMessage && (
              <Text mt={2} color={'error'} sx={{ fontSize: 12 }}>
                {props.errorMessage}
              </Text>
            )}
          </Box>
        </Flex>
      ) : (
        <Box sx={{ textAlign: 'left' }}>
          {props.label && <Label sx={{ mb: '2' }}>{props.label}</Label>}
          <Flex>
            <Input
              sx={{ width: props.inputWidth, ...borderStyle }}
              placeholder={props.placeholder}
              onChange={props.onChange}
              disabled={props.disabled}
              ref={props.ref}
              // type={showPassword ? 'text' : 'password'}
              // variant={props.isInvalid && 'error' || 'input'}
              {...props}
            />
            {/* {props.isPassword && (
              <IconButton
                icon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'relative',
                  right: '30px'
                }}
              />
            )}
            {props.icon && (
              <IconButton
                icon={props.icon}
                style={{
                  position: 'relative',
                  right: '30px'
                }}
              />
            )} */}
          </Flex>
          {props.errorMessage && (
            <Text mt={2} color={'error'} sx={{ fontSize: 12 }}>
              {props.errorMessage}
            </Text>
          )}
        </Box>
      )}
    </Box>
  )
}
