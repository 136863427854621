import React from "react";
import { Box, Flex, Label, Text } from "@theme-ui/components";

const SelectionSquareComponent = ({
  options,
  onSelect,
  label,
  description,
  selectedValue,
  width = '82px',
  height = '98px',
  padding = '10px'
}: {
  options: Array<any>;
  onSelect: any | VoidFunction;
  label?: string;
  description?: string;
  selectedValue?: string;
  width?: string
  height?: string
  padding?: string
}) => {
  return (
    <Box>
      {label && (
        <Box sx={{ fontWeight: "bold", mb: "5px" }}>{label}</Box>
      )}
      {description && (
        <Box sx={{ fontSize: "14px", mb: "12px" }}>{description}</Box>
      )}
      <Flex>
        {options.map((data) => {
          return (
            <Label
              key={data.value}
              sx={{
                borderColor: data.value === selectedValue ? "primary" : "gray1",
                borderWidth: "1px",
                borderStyle: "solid",
                padding: padding,
                borderRadius: "m",
                width: width,
                height: height,
                mr: "7px",
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  onSelect(data);
                }}
              >
                <Box>
                  {data.icon}
                </Box>
                <Box>
                  {data.label && (
                    <Text sx={{ fontSize: "12px" }}>{data.label}</Text>
                  )}
                </Box>
              </Flex>
            </Label>
          );
        })}
      </Flex>
    </Box>
  );
};

export default SelectionSquareComponent;
