// components/user/Text.js
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNode } from "@craftjs/core";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Switch,
  Text,
  Image as Img,
  Spinner,
} from "@theme-ui/components";
import { FaRegCircle, FaRegSquare, FaSquareFull } from "react-icons/fa";

import ImageComp from "../landingPage/ImageComp";
import { InputComponent } from "../Editor/Input";
import SelectionSquareComponent from "../Editor/SelectionSquare";
import SharedLayer from "../Editor/SharedLayer";
import Toast from "../../components/Editor/Toast";
import api from "../../utils/api";

const Image = ({
  isLink,
  shape,
  size,
  src,
  url,
}) => {
  const {
    connectors: { connect, drag },
    isActive,
  } = useNode((node) => ({
    isActive: node.events.selected,
  }));

  return (
    <div ref={(ref) => connect(drag(ref))}>
      <ImageComp src={src} isLink={isLink} url={url} shape={shape} size={size} />
    </div>
  );
};

const ImageSettings = () => {
  const {
    actions: { setProp },
    src,
    url,
    isLink,
    shape,
    size,
  } = useNode((node) => ({
    src: node.data.props.src,
    isLink: node.data.props.isLink,
    url: node.data.props.url,
    shape: node.data.props.shape,
    size: node.data.props.size,
  }));

  const {
    isLoading: isUploadLoading,
    mutate,
  } = useMutation((values: any) => api.uploadImage(values), {
    onSuccess: (data) => {
      setProp((props) => (props.src = data.data));
      Toast({
        title: 'Upload Berhasil',
        message: 'Gambar berhasil diupload',
        type: 'success',
      });
    }});

  const [isReadImageLoading, setIsReadImageLoading] = useState<boolean>(false);
  const fileInputRef = useRef(null);

  const optionsShape = [
    {
      label: "Segiempat",
      value: "square",
      icon: <FaRegSquare size={25} />,
    },
    {
      label: "Lingkaran",
      value: "circle",
      icon: <FaRegCircle size={25} />,
    },
  ];

  const optionsSize = [
    {
      label: "Penuh",
      value: "XL",
      icon: <FaSquareFull size={25} />,
    },
    {
      label: "Besar",
      value: "L",
      icon: <FaSquareFull size={20} />,
    },
    {
      label: "Sedang",
      value: "M",
      icon: <FaSquareFull size={15} />,
    },
    {
      label: "Kecil",
      value: "S",
      icon: <FaSquareFull size={10} />,
    },
  ];

  function onSelectShape(data) {
    setProp((props) => (props.shape = data.value));
  }

  function onSelectSize(data) {
    setProp((props) => (props.size = data.value));
  }

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      setIsReadImageLoading(true);
      reader.readAsDataURL(file);
      reader.onload = () => {
        setIsReadImageLoading(false);
        resolve({
          fileName: `${file.name}-${file.lastModified}`,
          file: reader.result
        })
      };
      reader.onerror = error => {
        setIsReadImageLoading(false);
        reject(error)
      };
    });
  }

  function handleChangeInput(e) {
    e.target.files.length > 0 && fileToBase64(e.target.files[0])
      .then((data) => {
        mutate(data);
      })
  }

  function handleDeleteImg() {
    setProp((props) => props.src = null )
  }

  function handleClickUpload(e) {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current.click();
  }

  function onChangeSwitch() {
    setProp((props) => {
      props.isLink = !props.isLink
    });
  }

  function onChangeUrl(e) {
    setProp((props) => (props.url = e.target.value));
  }

  return (
    <Container px={2}>
      <Box
        sx={{
          borderRadius: "m",
          borderColor: "gray0",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Flex
          py={2}
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: "15px",
            textAlign: "center",
            height: "250px",
          }}
        >
          <Input
            type="file"
            ref={fileInputRef}
            accept="image/png, image/jpg, image/gif"
            sx={{ display: "none" }}
            onChange={handleChangeInput}
          />

          {isReadImageLoading || isUploadLoading ?
          <Spinner/> :
          src ? (
            <Img
              src={src}
              alt="uploaded-image"
              sx={{ maxWidth: "350px", height: "240px" }}
            />
          ) : (
            <>
              <Text sx={{ pb: "15px" }}>
                Unggah media jpg, png, atau gif ke sini
              </Text>

              <Button variant="outline" onClick={handleClickUpload}>
                Unggah media
              </Button>
            </>
          )}
        </Flex>
      </Box>

      <Box py="15px">
        {src && (
          <Flex mb="15px">
            <Button
              variant="outline"
              sx={{ width: "100%", mr: "5px" }}
              onClick={handleClickUpload}
            >
              Ubah
            </Button>
            <Button
              variant="outline"
              sx={{ width: "100%", ml: "5px" }}
              onClick={handleDeleteImg}
            >
              Hapus
            </Button>
          </Flex>
        )}

        <Switch label="Link gambar" onChange={onChangeSwitch} checked={isLink} />

        {isLink && (
          <InputComponent
            sx={{ marginTop: "15px" }}
            placeholder="https://"
            value={url}
            onChange={onChangeUrl}
          />
        )}
      </Box>

      <Box sx={{ my: '15px' }}>
        <SelectionSquareComponent
          label="Bentuk"
          description="Pilih bentuk gambar yang ingin ditampilkan"
          options={optionsShape}
          onSelect={onSelectShape}
          selectedValue={shape}
        />
      </Box>

      <Box sx={{ my: '15px' }}>
        <SelectionSquareComponent
          label="Ukuran"
          description="Pilih ukuran gambar yang diinginkan"
          options={optionsSize}
          onSelect={onSelectSize}
          selectedValue={size}
        />
      </Box>
    </Container>
  );
};

Image.craft = {
  name: "Image",
  props: {
    isLink: false,
    shape: "square",
    size: "M",
    src: "https://cdn.pixabay.com/photo/2016/09/08/22/43/books-1655783__480.jpg",
    url: "https://cdn.pixabay.com/photo/2016/09/08/22/43/books-1655783__480.jpg",    
  },
  related: {
    settings: ImageSettings,
    layer: SharedLayer,
  },
};

export default Image;
