import React from 'react';
import { Composition } from 'atomic-layout';
import { useEditor, ROOT_NODE } from '@craftjs/core';
import { Box } from 'theme-ui';
import { DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';

export const Layers: React.FC = ({ children }) => {
  const { actions, allDescendants,  query: { node }, hoveredNodeId } = useEditor((state, query) => {
    const allDescendants = query.node(ROOT_NODE).descendants();

    return { allDescendants, hoveredNodeId: state.events.hovered }
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const moveToIndex = result.destination.index >= result.source.index ?
      (result.destination.index + 1) : result.destination.index;

    actions.move(
      hoveredNodeId,
      ROOT_NODE,
      moveToIndex,
    );
  }

  return (
    <DragDropContext onDragUpdate={onDragEnd}>
      <Droppable droppableId="layers">
        {provided => (
          <Composition
            width={'100%'}
            inline
            templateRows={`repeat(auto-fit, 50px)`}
            gap={16}
            paddingVertical={16}
            ref={provided.innerRef} {...provided.droppableProps}
          >
            {
              allDescendants.map((descendant, index) => {
                const descendantNode = node(descendant);
                return (
                  <Draggable
                    draggableId={descendant}
                    index={index}
                    key={descendant}
                  >
                    {provided => (
                      <Box
                        sx={{ width: '100%', cursor: 'pointer' }}
                        role="button"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          React.createElement(
                            descendantNode.get().related.layer,
                          )
                        }
                      </Box>
                    )}
                  </Draggable>
                  
                )
              })
            }
            {provided.placeholder}
          </Composition>
        )}
      </Droppable>
    </DragDropContext>
  );
}