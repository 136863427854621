import React from 'react'
import { Container, Grid } from 'theme-ui'
import { useEditor } from '@craftjs/core'
import { Layers } from '../Editor/Layers';
import ComponentPicker from './ComponentPicker'
import GroupButton from './GroupBtn';

const Toolbox = () => {
  const {
    actions,
    selectedNodeId,
    query: { createNode, node },
    settingsComponent,
  } = useEditor((state) => {
    return {
      selectedNodeId: state.events.selected,
      settingsComponent:
        state.nodes[state.events.selected] &&
        state.nodes[state.events.selected].related.settings,
    }
  })

  return (
    <Container px={2} py={2}>
      <Grid gap={1}>
        <ComponentPicker />
        {settingsComponent ? (
          <>
            {React.createElement(settingsComponent)}
            <GroupButton
              onClick={() => {
                actions.selectNode()
              }}
              onDelete={() => {
                actions.delete(selectedNodeId);
              }}
              onCopy={() => {
                const selectedNode = node(selectedNodeId).get();
                const { data: {type, props}} = selectedNode;
                const parentNode = selectedNode.data.parent;

                actions.add(
                  createNode(React.createElement(type, props)),
                  parentNode,
                  node(parentNode).get().data.nodes[selectedNodeId],
                );
              }}
            />
          </>
        ) : (
          <Layers/>
        )}
      </Grid>
    </Container>
  )
}

export default Toolbox
