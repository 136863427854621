// components/user/Text.js
import React from "react";
import { useNode } from "@craftjs/core";
import { Box, Text } from "theme-ui";
import { InputComponent } from "../Editor/Input";
import VideoComp from "../landingPage/VideoComp";
import SharedLayer from "../Editor/SharedLayer";

const Video = ({ src }) => {
  const { connectors: {connect, drag}, isActive, actions: {setProp} } = useNode((node) => ({
    isActive: node.events.selected
  }));

  return (
    <div ref={ref => connect(drag(ref))}>
      <VideoComp url={src} />
    </div>
  )
}

const VideoSettings = () => {
  const { actions: {setProp}, src } = useNode((node) => ({
    src: node.data.props.src
  }));

  return (
    <Box>
      <InputComponent value={src} label="Link Video" placeholder="Masukkan link YouTube/Twitter/TikTok" onChange={(event) => {setProp(props => props.src = event.target.value)}} />
      <Text sx={{ fontStyle: 'italic', fontSize: 12 }}>*Tampilkan video dari YouTube, Twitter, atau TikTok</Text>
    </Box>
  )
}

Video.craft = {
  name: 'Video',
  related: {
    settings: VideoSettings,
    layer: SharedLayer,
  },
}

export default Video