import React from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Box, Grid, Text } from "theme-ui";
import { Composition } from 'atomic-layout';
import { DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';

import SharedLayer from "../Editor/SharedLayer";
import Product from "./Product";
import Button from "../Button";

const Products = (props) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <Composition
      width={'100%'}
      inline
      templateCols={`1fr 1fr`}
      gap={20}
      ref={(ref) => connect(drag(ref))}
      {...props}
    >
    </Composition>
  );
};

const ProductsSettings = () => {
  const { actions, allDescendants, hoveredNodeId, layers,  query, selectedNodeId } = useEditor((state, query) => {
    const allDescendants = query.node(state.events.selected).descendants();

    return {
      allDescendants,
      hoveredNodeId: state.events.hovered,
      layers,
      selectedNodeId: state.events.selected,
    }
  });

  function handleAdd() {
    const nodeTree = query.parseReactElement(
      <Product/>
    ).toNodeTree()
    actions.addNodeTree(nodeTree, selectedNodeId)
  }
  
  function onDragUpdate(result) {
    if (!result.destination) {
      return;
    }

    const moveToIndex = result.destination.index >= result.source.index ?
      (result.destination.index + 1) : result.destination.index;

    actions.move(
      hoveredNodeId,
      selectedNodeId,
      moveToIndex,
    );
  }
  
  return (
    <DragDropContext onDragUpdate={onDragUpdate}>
      <Droppable droppableId="products">
        {provided => (
          <Box
            ref={provided.innerRef} {...provided.droppableProps}
          >
            <Box
              py={2}
              sx={{
                textAlign: "center"
              }}
            >
              <Text sx={{ fontWeight: "bold", fontSize: "18px", mb: "5px" }}>
                Ayo tambah konten!
              </Text>
              <br />
              <Text sx={{ fontSize: "14px" }}>
                Mulai tambah produk dengan klik tombol di bawah
              </Text>
            </Box>
            <Grid
              columns={[1, '1fr']}
            >
              {allDescendants.map((descendant, index) => {
                const descendantNode = query.node(descendant);
                return (
                  <Draggable
                    draggableId={descendant}
                    index={index}
                    key={descendant}
                  >
                    {provided => (
                      <Box
                        sx={{ width: '100%', cursor: 'pointer' }}
                        role="button"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          React.createElement(
                            descendantNode.get().related.layer,
                          )
                        }
                      </Box>
                    )}
                  </Draggable>
                )
              })}
            </Grid>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      <Box
        py={2}
      >
        <Button full onClick={handleAdd}>Tambah</Button>
      </Box>
    </DragDropContext>
  )
};

Products.craft = {
  name: 'Katalog Produk',
  related: {
    settings: ProductsSettings,
    layer: SharedLayer,
  },
};

export default Products;
