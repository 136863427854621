import React, { useState } from 'react'
import { Box, Button, Flex, Grid, Input, Select, Image } from 'theme-ui'
import { useEditor, useNode, useNodeReturnType } from '@craftjs/core'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';
import SharedLayer from '../Editor/SharedLayer'
// import Text from '../UserComponents/Text'
import Link from './Link';

interface LinksProps {
  links: Array<{
    id?: number | string
    url: string
    type:
      | 'tokopedia'
      | 'shopee'
      | 'bukalapak'
      | 'lazada'
      | 'whatsapp'
      | 'line'
      | 'url'
      | 'upload'
    variant?: string
    name?: string
    icon?: string /**@todo implement icon, and upload icon */
  }>
}

function Links(props: LinksProps) {
  const {
    connectors: { connect, drag },
  } = useNode<LinksProps>()

  return (
    <Box ref={(ref) => connect(drag(ref))} {...props}>
      {/* {allDescendants.map((link) => {
        console.log('test')
        return React.createElement(link);
      })} */}
    </Box>
  )
}

function LinksSettings() {
  const [edit, setEdit] = useState(null)
  const { setProp, links } = useNode<LinksProps>((node) => ({
    links: node.data.props.links,
  }))

  const { actions, allDescendants, hoveredNodeId, layers,  query, selectedNodeId } = useEditor((state, query) => {
    const allDescendants = query.node(state.events.selected).descendants();

    return {
      allDescendants,
      hoveredNodeId: state.events.hovered,
      layers,
      selectedNodeId: state.events.selected,
    }
  });

  function handleAdd() {
    const nodeTree = query.parseReactElement(
      <Link
        type='url'
        name={`url${allDescendants.length + 1}`}
        url='https://google.com'
        icon={iconMap['url']}
      />
    ).toNodeTree()
    actions.addNodeTree(nodeTree, selectedNodeId)
  }

  function onDragUpdate(result) {
    if (!result.destination) {
      return;
    }

    const moveToIndex = result.destination.index >= result.source.index ?
      (result.destination.index + 1) : result.destination.index;

    actions.move(
      hoveredNodeId,
      selectedNodeId,
      moveToIndex,
    );
  }

  return (
    <DragDropContext onDragUpdate={onDragUpdate}>
      <Droppable droppableId="links">
        {provided => (
          <Box
            ref={provided.innerRef} {...provided.droppableProps}
          >
            <Grid
              columns={[1, '1fr']}
            >
              {allDescendants.map((descendant, index) => {
                const descendantNode = query.node(descendant);
                return (
                  <Draggable
                    draggableId={descendant}
                    index={index}
                    key={descendant}
                  >
                    {provided => (
                      <Box
                        sx={{ width: '100%', cursor: 'pointer' }}
                        role="button"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          React.createElement(
                            descendantNode.get().related.layer,
                          )
                        }
                      </Box>
                    )}
                  </Draggable>
                )
                // return (
                //   <LinkSettingItem
                //     key={link.id}
                //     link={link}
                //     isEdit={edit === link.id}
                //     onEdit={({ id }) => setEdit(id === edit ? null : id)}
                //     setProp={setProp}
                //   />
                // )
              })}
            </Grid>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      <Button onClick={handleAdd}>Tambah</Button>
    </DragDropContext>
  )
}

function LinkSettingItem({
  link,
  setProp,
  isEdit,
  onEdit,
}: {
  link: LinksProps['links'][0]
  setProp: useNodeReturnType<any>['setProp']
  isEdit: boolean
  onEdit?(link: LinksProps['links'][0])
}) {
  const { id, type, url, name, icon, variant } = link

  const handleEdit = () => onEdit(link)

  function handleDelete({ type, url, name }) {
    return () => {
      setProp((props: LinksProps) => {
        props.links = [
          ...props.links.filter(
            (link) =>
              `${link.url}${link.type}${link.name}` !== `${url}${type}${name}`
          ),
        ]
      })
    }
  }

  function update(fields: Partial<LinksProps['links'][0]>) {
    setProp((props: LinksProps) => {
      props.links = [
        ...props.links.map((link) => {
          if (link.id === id) return { ...link, ...fields }
          return link
        }),
      ]
    })
  }

  function handleSelectType(e) {
    const type = e.target.value
    const icon = iconMap[type]
    https: update({ type: e.target.value, icon })
  }

  return (
    <Box
      sx={{
        border: '1px solid var(--theme-ui-colors-muted)',
        borderRadius: 4,
        mb: 3,
        px: 2,
        py: 3,
        boxShadow: isEdit ? '-2px 4px 10px -5px grey' : 'unset',
      }}
    >
      <Flex>
        <Box sx={{ flex: 2 }}>{name}</Box>

        <Button mr={1} bg="transparent" p={0} color="gray" onClick={handleEdit}>
          <FaPencilAlt />
        </Button>
        <Button
          mr={1}
          bg="transparent"
          p={0}
          color="gray"
          onClick={handleDelete({ type, url, name })}
        >
          <FaTrash />
        </Button>
      </Flex>
      {isEdit && (
        <Box py={2}>
          <Input
            placeholder="Display Name"
            bg="white"
            mb={2}
            value={name}
            onChange={(e) => update({ name: e.target.value })}
          />
          <Input
            placeholder="Enter URL"
            bg="white"
            mb={2}
            value={url}
            onChange={(e) => update({ url: e.target.value })}
          />
          <Select
            placeholder="Type"
            value={type}
            mb={2}
            onChange={handleSelectType}
          >
            <option value=""></option>
            <option value="tokopedia">Tokopedia</option>
            <option value="shopee">Shopee</option>
            <option value="bukalapak">Bukalapak</option>
            <option value="lazada">Lazada</option>
            <option value="whatsapp">Whatsapp</option>
            <option value="line">Line</option>
            <option value="url">URL</option>
            <option value="url">Upload</option>
          </Select>
          <Input
            placeholder="Enter Icon URL"
            bg="white"
            mb={2}
            value={icon}
            onChange={(e) => update({ icon: e.target.value })}
          />
        </Box>
      )}
    </Box>
  )
}

Links.craft = {
  name: 'Katalog Link',
  related: {
    settings: LinksSettings,
    layer: SharedLayer,
  },
}

export default Links

/**
 * @todo use proper icons src
 */

const iconMap = {
  tokopedia:
    'https://www.freepnglogos.com/uploads/logo-tokopedia-png/berita-tokopedia-info-berita-terbaru-tokopedia-6.png',
  shopee:
    'https://www.freepnglogos.com/uploads/shopee-logo/shopee-circle-logo-design-shopping-bag-13.png',
  bukalapak:
    'https://bursadapur.co.id/wp-content/uploads/2020/03/icon-bukalapak-png-4-1.png',
  lazada:
    'https://4.bp.blogspot.com/-d7ejX_qMOTI/XgrnnlT-VwI/AAAAAAAABPw/GtwyGgILiF8aWtCaYoKEKokgsokXi4rIQCLcBGAsYHQ/s1600/lazada.png',
  whatsapp:
    'https://w7.pngwing.com/pngs/672/164/png-transparent-whatsapp-icon-whatsapp-logo-computer-icons-zubees-halal-foods-whatsapp-text-circle-unified-payments-interface.png',
  line: 'https://www.pngfind.com/pngs/m/109-1093551_line-chat-icon-logo-line-messenger-logo-vector.png',
  url: 'https://cdn2.iconfinder.com/data/icons/pittogrammi/142/95-512.png',
}
