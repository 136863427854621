import React from "react";
import { Box, Image, Text, Flex } from "@theme-ui/components";

import { CurrencyFormat } from "../Editor/CurrencyFormat";

type ProductComp = {
  showPrice?: boolean;
  showLogo?: boolean;
  title?: string;
  price?: number;
  image?: string;
  type?: string;
};

const ProductComp = ({
  showPrice = false,
  showLogo = false,
  title = "NAMA PRODUK",
  price = 0,
  image = "https://www.freeiconspng.com/uploads/no-image-icon-6.png",
  type = "default",
}: ProductComp) => {
  const logo =
    type === "Tokopedia"
      ? "https://www.freepnglogos.com/uploads/logo-tokopedia-png/berita-tokopedia-info-berita-terbaru-tokopedia-6.png"
      : type === "Shopee"
      ? "https://www.freepnglogos.com/uploads/shopee-logo-png/shopee-logo-shop-with-the-gentlemen-collection-and-win-the-shopee-0.png"
      : "http://farmalkes.kemkes.go.id/wp-content/uploads/2021/01/White-Square.jpg";

  return (
    <Box
      sx={{
        position: "relative",
        display: "block",
        background: "white",
        boxSizing: "border-box",
        // width: "149px",
        overflow: "hidden",
        borderRadius: "5px",
        // margin: "5% 5% 5% 0",
        boxShadow: "3px 3px 5px 0px rgb(0 0 0 / 35%)",
      }}
    >
      {showLogo && (
        <Box
          sx={{
            zIndex: 0,
            width: "32px",
            height: "32px",
            position: "absolute",
            right: 0,
            top: "2px",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={logo} alt={type} width="22px" sx={{ display: "block" }} />
        </Box>
      )}
      <Image
        src={image}
        width="100%"
        sx={{ display: "block", borderBottom: "1px solid rgba(0,0,0,0.22)" }}
      />
      <Box
        sx={{
          padding: "0 5%",
          textAlign: "left",
          fontWeight: "bold",
          fontSize: "13px",
          margin: "10px 0",
          lineHeight: "20px",
          height: "40px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Box>
      {showPrice && (
        <Box
          sx={{
            textAlign: "left",
            padding: "0 5%",
            margin: 0,
            height: "25px",
            fontSize: "12px",
          }}
        >
          <CurrencyFormat value={price} />
        </Box>
      )}
    </Box>
  );
};

export default ProductComp;
