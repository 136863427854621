import React, { ReactNode, useState } from 'react';
import { Box, Flex, Text } from 'theme-ui';
/**@todo change import path, dont use relative path */
// import { keyframes } from '../../../node_modules/@emotion/core';

interface SidebarProps {
  menus?: MenuProps[];
}

export default function Sidebar(props: SidebarProps) {
  return (
    <Box
      sx={{
        bg: 'sheet',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        borderRightStyle: 'solid',
        borderRightColor: 'sunken',
        borderRightWidth: 1,
      }}
      role="navigation"
    >
      <Box>
        {props.menus?.map((menu, i) => {
          return <Menu key={i} {...menu} />;
        })}
      </Box>
    </Box>
  );
}

// =============================================================================
// Menu
// =============================================================================

interface MenuProps {
  icon: ReactNode;
  label: ReactNode;
  onClick?(): void;
  isActive?: boolean;
}

function Menu({ icon: Icon, label, onClick, isActive }: MenuProps) {
  const [hovered, setHovered] = useState(false);

  function handleEvent(type: string, event: any) {
    switch (type) {
      case 'mouseEnter':
        return setHovered(true);
      case 'mouseLeave':
        return setHovered(false);
      default:
        return;
    }
  }

  return (
    <Flex
      as="li"
      color={isActive && "primary"}
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        zIndex: 5,
        ':hover': {
          bg: 'gray1',
          color: 'black',
        },
        bg: isActive ? 'white' : ''
      }}
      // onMouseEnter={(e) => handleEvent('mouseEnter', e)}
      // onMouseLeave={(e) => handleEvent('mouseLeave', e)}
      onClick={onClick}
    >
      <Flex
        sx={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 5,
          flexDirection: 'column',
          py: 2,
        }}
      >
        {Icon}
        <Text sx={{ mt: '2px' }}>{label}</Text>
      </Flex>
      {/* {hovered && (
        <Flex
          pr="3"
          sx={{
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            left: '100%',
            whiteSpace: 'nowrap',
            bg: 'primary',
            zIndex: 4,
            color: 'transparent',
            transformOrigin: 'left',
          }}
        >
          {label}
        </Flex>
      )} */}
    </Flex>
  );
}
