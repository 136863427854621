import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export function CurrencyFormat({ value: val, ...props }: NumberFormatProps) {
  const value = typeof val !== 'number' ? parseFloat(val) : val;

  return (
    <NumberFormat
      thousandSeparator="."
      decimalSeparator=","
      prefix="Rp "
      displayType="text"
      decimalScale={2}
      value={value}
      {...props}
    />
  );
}

export function PercentageFormat({ value: val, ...props }: NumberFormatProps) {
  const value = typeof val !== 'number' ? parseFloat(val) : val;

  return (
    <NumberFormat
      thousandSeparator="."
      decimalSeparator=","
      suffix="% OFF"
      displayType="text"
      decimalScale={2}
      value={value}
      {...props}
    />
  )
}