import { API_ROOT } from "../api";
import isBuildTime from "../isBuildTime";

// Gatsby build breaks the usual import hence the special import using require syntax
const ahoy = !isBuildTime() ? require("./lib/index") : null;

if (!isBuildTime()) {
  ahoy.default.configure({
    urlPrefix: API_ROOT,
    visitsUrl: `/ahoy/visits`,
    eventsUrl: `/ahoy/events`,
    page: null,
    platform: "Web",
    useBeacon: true,
    startOnReady: false,
    trackVisits: true,
    cookies: true,
    cookieDomain: null,
    headers: {},
    withCredentials: false,
    visitDuration: 4 * 60, // 4 hours
    visitorDuration: 2 * 365 * 24 * 60, // 2 years
  });
}

export default ahoy;
