import React, { useState, ReactElement, ReactNode } from 'react'
import { Element, useEditor, ROOT_NODE } from '@craftjs/core'
import { Button, Box, Grid, BoxProps, Flex, Text as TextTU } from 'theme-ui'
import Text from '../UserComponents/Text'
import Video from '../UserComponents/Video'
import { FaChevronLeft, FaPercent, FaPlus } from 'react-icons/fa'
import { MdImage, MdLink, MdShoppingBasket, MdStars, MdTitle, MdVerticalDistribute, MdVideocam } from 'react-icons/md'
import SocialIcons from '../UserComponents/SocialIcons'
import Links from '../UserComponents/Links'
import Image from '../UserComponents/Image'
import Divider from '../UserComponents/Divider'
import Promo from '../UserComponents/Promo'
import Products from '../UserComponents/Products'
import useOnclickOutside from "react-cool-onclickoutside";

export default function ComponentPicker() {
  const [isOpen, setIsOpen] = useState(false)
  const { query, actions, selectedNodeId } = useEditor((state, query) => {
    return {
      selectedNodeId: state.events.selected,
    }
  })

  const closePopper = () => {
    setIsOpen(false);
  }

  const ref = useOnclickOutside(() => closePopper())

  const addNode = (element: ReactElement) => () => {
    const nodeTree = query.parseReactElement(element).toNodeTree()
    actions.addNodeTree(nodeTree, ROOT_NODE)
    setIsOpen(false)
  }

  if (selectedNodeId) {
    return (
      <Button
        role="button"
        onClick={() => {
          if (query.node(query.node(selectedNodeId).get().data.parent).isTopLevelNode()) {
            actions.selectNode()
          } else {
            actions.selectNode(query.node(selectedNodeId).get().data.parent)
          }
        }}
        variant="iconBtn"
        sx={{ width: '80px', ml: 'auto', border: 'unset' }}
      >
        <Box as={FaChevronLeft} sx={{ mb: '-2px', mr: 1 }} />
        Back
      </Button>
    )
  }

  return (
    <Box sx={{ position: 'relative' }} ref={ref}>
      <Button onClick={() => setIsOpen(!isOpen)} sx={{ width: '100%' }}>
        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <TextTU sx={{ mr: '5px' }}>Tambah bagian</TextTU>
          <FaPlus size={12} />
        </Flex>
      </Button>

      <Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Grid gap={1} columns={4}>
          <UserCompGrid onClick={addNode(<Text text="Hello World" />)} icon={<MdTitle size={20} />} title="Teks" />
          <UserCompGrid
            onClick={addNode(
              <Video src="https://www.youtube.com/watch?v=Dmrp2spuOr0" />
            )}
            icon={<MdVideocam size={20} />}
            title="Video"
          />
          <UserCompGrid
            onClick={addNode(
              <Element
                is={SocialIcons}
                canvas
              />
            )}
            icon={<MdStars size={20} />}
            title="Icon"
          />
          <UserCompGrid
            onClick={addNode(
              <Element
                is={Links}
                canvas
              />
            )}
            icon={<MdLink size={20} />}
            title="Link"
          />
          <UserCompGrid
            onClick={addNode(
              <Image />
            )}
            title="Image"
            icon={<MdImage size={20} />}
          />
          <UserCompGrid
            onClick={addNode(
              <Divider color={'#000000'} size={1} dividerType={"line"} />
            )}
            title="Divider"
            icon={<MdVerticalDistribute size={20} />}
          />
          <UserCompGrid
            onClick={addNode(
              <Element
                is={Products}
                canvas
              />
            )}
            title="Product"
            icon={<MdShoppingBasket size={20} />}
          />
          <UserCompGrid
            onClick={addNode(
              <Promo
                platform={"other"}
                storeName={"Store name"}
                url={null}
                voucherAmount={"Nominal"}
                voucherName={"Voucher name"}
                voucherType={"amount"}
              />
            )}
            title="Voucher"
            icon={<FaPercent size={20} />}
          />
        </Grid>
      </Popup>
    </Box>
  )
}

function Popup({ isOpen = false, onClose = null, children = null }) {
  if (!isOpen) return null

  return (
    <Box
      sx={{
        bg: 'white',
        zIndex: 200,
        minHeight: 300,
        minWidth: 300,
        p: 2,
        border: '1px solid var(--theme-ui-colors-muted)',
        position: 'absolute',
        top: '110%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Button
        onClick={onClose}
        bg="transparent"
        color="primary"
        mb={1}
        sx={{ alignSelf: 'end' }}
      >
        Close
      </Button> */}
      {children}
    </Box>
  )
}

interface UserCompGridProps extends BoxProps {
  icon: ReactNode
  title: string
}

const UserCompGrid = (props: UserCompGridProps) => {
  const {icon=null, title=""} = props

  return (
    <Box
      role="button"
      {...props}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        bg: 'gray1',
        height: 70,
        borderRadius: 's',
        ...props.sx,
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <Box>{icon}</Box>
        <TextTU>{title}</TextTU>
      </Flex>
    </Box>
  )
}
