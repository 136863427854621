// import { lighten } from '@theme-ui/color'
// import { FormikErrors } from 'formik'
import React from 'react'
import {
  Label,
  ForwardRef,
  Flex,
  Select,
  SelectProps,
  Box,
  Text,
} from 'theme-ui'

export interface SelectionProps extends SelectProps {
  label?: string
  row?: boolean
  isInvalid?: string | boolean
  errorMessage?: string | string[]
  placeholder?: string
}

const Selection: ForwardRef<HTMLSelectElement, SelectionProps> =
  React.forwardRef(({ children, label, name, row, placeholder, value, ...props }) => {
    const borderStyle = {
      borderRadius: 's',
      border: '1px solid',
      borderColor: props.isInvalid || props.errorMessage ? 'error' : 'primary',
      bg:
        props.isInvalid || props.errorMessage
          ? 'error'
          : 'unset'
    }

    return (
      <Box>
        {row ? (
          <Flex sx={{ alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <Label htmlFor={name}>{label}</Label>
            </Box>
            <Box sx={{ flex: 5, textAlign: 'left' }}>
              <Select
                id={name}
                name={name}
                placeholder={placeholder}
                sx={borderStyle}
                value={value}
                {...props}
              >
                {children}
              </Select>
              {props.errorMessage && (
                <Text mt={2} color={'error'} sx={{ fontSize: 12 }}>
                  {props.errorMessage}
                </Text>
              )}
            </Box>
          </Flex>
        ) : (
          <Box sx={{ alignItems: 'center' }}>
            <Box>
              <Label htmlFor={name} mb={2}>
                {label}
              </Label>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Select
                id={name}
                name={name}
                placeholder={placeholder}
                sx={borderStyle}
                value={value}
                {...props}
              >
                {children}
              </Select>
              {props.errorMessage && (
                <Text mt={2} color={'error'} sx={{ fontSize: 12 }}>
                  {props.errorMessage}
                </Text>
              )}
            </Box>
          </Box>
        )}
      </Box>
    )
  })

export default Selection
