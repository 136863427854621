import React from "react";
import { useNode } from "@craftjs/core";
import { Box, Flex, Image, Text } from "@theme-ui/components";

import { InputComponent } from "../Editor/Input";
import SelectionSquareComponent from "../Editor/SelectionSquare";
import PromoComp from "../landingPage/PromoComp";
import SharedLayer from "../Editor/SharedLayer";

const Promo = ({ platform, storeName, url, voucherAmount, voucherName, voucherType }) => {
  const { connectors: {connect, drag}, isActive, actions: {setProp} } = useNode((node) => ({
    isActive: node.events.selected
  }));

  return (
    <div ref={ref => connect(drag(ref))}>
      <PromoComp
        platform={platform}
        storeName={storeName}
        url={url}
        voucherAmount={voucherAmount}
        voucherName={voucherName}
        voucherType={voucherType}
      />
    </div>
  )
}

const PromoSettings = () => {
  const {
    actions: {setProp},
    platform,
    storeName,
    url,
    voucherAmount,
    voucherName,
    voucherType,
  } = useNode((node) => ({
    platform: node.data.props.platform,
    storeName: node.data.props.storeName,
    url: node.data.props.url,
    voucherAmount: node.data.props.voucherAmount,
    voucherName: node.data.props.voucherName,
    voucherType: node.data.props.voucherType,
  }));

  const options = [
    {
      label: 'Shopee',
      icon: <Image src="https://www.freepnglogos.com/uploads/shopee-logo-png/shopee-logo-shop-with-the-gentlemen-collection-and-win-the-shopee-0.png" width="45px" height="45px" />,
      value: 'shopee',
    },
    {
      label: 'Tokopedia',
      icon: <Image src="https://www.freepnglogos.com/uploads/logo-tokopedia-png/berita-tokopedia-info-berita-terbaru-tokopedia-6.png" width="45px" height="45px" />,
      value: 'tokopedia',
    },
    {
      label: 'Other',
      icon: <Image src="https://www.desty.app/assets/iconOther.4c99d489.svg" width="45px" height="45px" />,
      value: 'other',
    },
  ]

  const optionsDiscType = [
    {
      icon: <Text>Rp</Text>,
      value: 'amount',
    },
    {
      icon: <Text>% off</Text>,
      value: 'percentage',
    },
  ]

  return (
    <Box>
      <Box>
        <Box sx={{ my: '15px' }}>
          <SelectionSquareComponent
            label="Platform"
            description="Ubah gaya voucher kamu"
            options={options}
            onSelect={(child) => setProp((props) => props.platform= child?.value )}
            selectedValue={platform}
          />
        </Box>

        <Box p="10px 0px 20px 0px">
          <InputComponent
            label="Link produk atau toko"
            placeholder="Masukkan url produk atau toko Anda"
            onChange={(e) => setProp((props) => props.url = e.target.value )}
            value={url}
          />
        </Box>

        <Box pb="20px">
          <InputComponent 
            label="Nama toko"
            onChange={(e) => setProp((props) => props.storeName= e.target.value )}
            value={storeName}
          />
        </Box>

        <Box pb="20px">
          <InputComponent 
            label="Nama voucher"
            onChange={(e) => setProp((props) => props.voucherName = e.target.value )}
            value={voucherName}
          />
        </Box>

        <Flex pb="20px" sx={{ justifyContent: 'space-between' }}>
          <Box>
            <InputComponent 
              label="Nominal voucher"
              type="number"
              onChange={(e) => setProp((props) => props.voucherAmount = e.target.value )}
              value={voucherAmount}
            />
          </Box>
          <Flex sx={{ alignSelf: 'end' }}>
            <SelectionSquareComponent
              options={optionsDiscType}
              width="54px"
              height="33px"
              padding="2px"
              onSelect={(child) => setProp((props) => props.voucherType = child?.value )}
              selectedValue={voucherType}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

Promo.craft = {
  name: 'Promo',
  props: {
    platform: "other",
    storeName: "Store name",
    url: null,
    voucherAmount: "Nominal",
    voucherName: "Voucher name",
    voucherType: "amount",
  },
  related: {
    settings: PromoSettings,
    layer: SharedLayer,
  }  
}

export default Promo
