import React from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Box, Button, Flex, Grid } from "theme-ui";
import { DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';
import SharedLayer from "../Editor/SharedLayer";
import SocialIcon from "./SocialIcon";

const SocialIcons = (props) => {
  const {
    connectors: { connect, drag },
  } = useNode()

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      ref={(ref) => connect(drag(ref))}
      {...props}
    >
    </Flex>
  );
};

const SocialIconsSettings = () => {
  const {
    actions: { setProp },
    data,
  } = useNode((node) => ({
    data: node.data.props.data
  }));

  const { actions, allDescendants, hoveredNodeId, layers,  query, selectedNodeId } = useEditor((state, query) => {
    const allDescendants = query.node(state.events.selected).descendants();

    return {
      allDescendants,
      hoveredNodeId: state.events.hovered,
      layers,
      selectedNodeId: state.events.selected,
    }
  });

  const deleteData = (id) => {
    const newData = data.filter((child) => child?.id !== id);

    setProp((props) => props.data = newData)
  };

  function handleAdd() {
    const nodeTree = query.parseReactElement(
      <SocialIcon
        type='default'
        url='https://google.com'
      />
    ).toNodeTree()
    actions.addNodeTree(nodeTree, selectedNodeId)
  }
  
  function onDragUpdate(result) {
    if (!result.destination) {
      return;
    }

    const moveToIndex = result.destination.index >= result.source.index ?
      (result.destination.index + 1) : result.destination.index;

    actions.move(
      hoveredNodeId,
      selectedNodeId,
      moveToIndex,
    );
  }

  return (
    <DragDropContext onDragUpdate={onDragUpdate}>
      <Droppable droppableId="socialIcons">
        {provided => (
          <Box
            ref={provided.innerRef} {...provided.droppableProps}
          >
            <Grid
              columns={[1, '1fr']}
            >
              {allDescendants.map((descendant, index) => {
                const descendantNode = query.node(descendant);
                return (
                  <Draggable
                    draggableId={descendant}
                    index={index}
                    key={descendant}
                  >
                    {provided => (
                      <Box
                        sx={{ width: '100%', cursor: 'pointer' }}
                        role="button"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          React.createElement(
                            descendantNode.get().related.layer,
                          )
                        }
                      </Box>
                    )}
                  </Draggable>
                )
              })}
            </Grid>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      <Button onClick={handleAdd}>Tambah</Button>
    </DragDropContext>
  )
};

SocialIcons.craft = {
  name: 'Katalog Ikon Sosial',
  related: {
    settings: SocialIconsSettings,
    layer: SharedLayer,
  },
};

export default SocialIcons;
