import React from 'react'
import { Flex, Box, IconButton, Button } from 'theme-ui'
import { FaRegTrashAlt, FaRegClone } from 'react-icons/fa'

type GroupButtonProps = {
  onClick?: any
  onDelete?: any
  onCopy?: any
}

const GroupButton = ({ onClick, onCopy, onDelete }: GroupButtonProps) => {
  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', pt: '50px' }}>
      <Box sx={{ flex: 1, textAlign: 'center', maxWidth: '45px' }}>
        <IconButton onClick={onDelete}>
          <FaRegTrashAlt size={20} />
        </IconButton>
      </Box>
      <Box sx={{ flex: 1, textAlign: 'center', maxWidth: '45px', pr: '9px' }}>
        <IconButton onClick={onCopy}>
          <FaRegClone size={20} />
        </IconButton>
      </Box>
      <Box sx={{ flex: 5, textAlign: 'center', maxWidth: '90%', display: 'grid' }}>
        <Button variant="primary" onClick={onClick}>Selesai</Button>
      </Box>
    </Flex>
  )
}

export default GroupButton
