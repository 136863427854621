import React from 'react'
import { Box } from 'theme-ui'
import { TwitterVideoEmbed } from 'react-twitter-embed'
import Embed, { defaultProviders } from 'react-tiny-oembed'


const TikTok = {
  "provider_name": "TikTok",
  "provider_url": "http://www.tiktok.com/",
  "endpoints": [
      {
          "schemes": [
              "https://www.tiktok.com/*/video/*"
          ],
          "url": "https://www.tiktok.com/oembed"
      }
  ]
};

const Twitter = {
  "provider_name": "Twitter",
  "provider_url": "http://www.twitter.com/",
  "endpoints": [
      {
          "schemes": [
              "https://twitter.com/*",
              "https://twitter.com/*/status/*",
              "https://*.twitter.com/*/status/*"
          ],
          "url": "https://publish.twitter.com/oembed"
      }
  ]
};

const VideoComp = ({ url = "" }) => {
  function YouTubeGetID(url: any){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

 function TwitterGetID(url: any){
    const regex = /status(?:es)?\/(\d+)/;
    const matchedUrl = url.match(regex);
    return matchedUrl && matchedUrl[1] || null;
  }

  const newSrc = (url: string) => {
    if(url.includes('youtu')) {
      return `https://www.youtube.com/embed/${YouTubeGetID(url)}`
    } else if (url.includes('twitter')) {
      return `https://twitter.com/i/videos/tweet/${TwitterGetID(url)}`
    }
  }

  return (
    <Box
      key={url}
    >
      {
        url.includes('twitter') ?
          <TwitterVideoEmbed
            id={TwitterGetID(url)}
          /> :
          <Embed
            providers={[
              ...defaultProviders,
              TikTok,
              Twitter
            ]}
            url={url}
          /> 
      }
    </Box>
  )

  return (
    <Box sx={{ position: 'relative', padding: '15px 0px', textAlign: 'center', zIndex: 0, }}>
      <Box sx={{ background: '0 0', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}></Box>
      <Box sx={{ zIndex: 0 }}>
        <iframe
          style={{ width: '90%' }}
          height="480"
          frameBorder="0"
          src={newSrc(url)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </Box>
  )
}

export default VideoComp
