/** @jsx jsx */
import { jsx, Box, BoxProps, Button, Container, Text, Flex, Input, Image, Grid } from 'theme-ui'
import { Composition } from 'atomic-layout';
import { useNode } from '@craftjs/core'
import React, { useRef, useState } from 'react'
import { FaChevronDown, FaTimes } from 'react-icons/fa'
import { SketchPicker } from 'react-color';
import useOnclickOutside from "react-cool-onclickoutside";
import { MdOutlineImage, MdOutlineList } from 'react-icons/md';
import Selection from '../Editor/Selection'
import ScrolledDiv from '../ScrolledDiv';
import Sidebar from '../../components/Layout/Sidebar';
import Toolbox from '../../components/Editor/Toolbox';


const RootContainer = ({ backgroundSetting, ...rest }) => {
  const {
    connectors: { connect, drag },
    isActive,
    actions: { setProp },
  } = useNode((node) => ({
    isActive: node.events.selected,
  }));

  const style = backgroundSetting.currentSetting === 'color' ?
    {
      bg: backgroundSetting[backgroundSetting.currentSetting].hex,
    } :
    {
      backgroundImage: `url(${backgroundSetting[backgroundSetting.currentSetting].url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };

  return (
    <Box
      sx={{
        height: '100%',
        ...style
      }}
    >
      <Composition
        as={ScrolledDiv}
        width={'100%'}
        height={'100%'}
        inline
        autoRows={`min-content`}
        templateCols={'1fr'}
        gap={20}
        {...rest}
      />
    </Box>
  )
}


interface BackgroundSetting {
  color: {};
  image: {};
  currentSetting: string;
}

const RootContainerSettings = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const closePopper = () => {
    setShowColorPicker(false);
  }

  const ref = useOnclickOutside(() => closePopper())

  const {
    actions: { setProp },
    backgroundSetting,
    isBackground,
    isToolbox,
  } = useNode((node) => ({
    backgroundSetting: node.data.props.backgroundSetting,
    data: node.data.props.data,
    isBackground: node.data.props.isBackground,
    isToolbox: node.data.props.isToolbox,
  }));

  const fileInputRef = useRef(null)

  function handleChangeInput(e) {
    const file = URL.createObjectURL(e.target.files[0])
    setProp(props => {
      props.backgroundSetting = {
        ...props.backgroundSetting,
        [props.backgroundSetting.currentSetting]: {
          url: file,
        }
      }
    });
  }

  function handleClickUpload(e) {
    fileInputRef.current.click()
  }

  function handleDeleteImg() {
    setProp(props => {
      props.backgroundSetting = {
        ...props.backgroundSetting,
        [props.backgroundSetting.currentSetting]: {
          url: null,
        }
      }
    });
  }

  return (isBackground ?
    <Container>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', height: '50px', borderBottom: '1px solid rgba(0,0,0,0.22)', color: 'primary', px: '15px' }}>
        <Text sx={{ fontSize: '16px', fontWeight: 'bold' }}>Latar Belakang</Text>
        {/* <IconButton onClick={handleXBtn} sx={{ cursor: 'pointer' }}>
          <FaTimes size={20} />
        </IconButton> */}
      </Flex>

      <Box px={'15px'} pt={'8px'}>
        <Selection
          // onChange={(e) => setBackgroundData({ ...backgroundData, type: e.target.value })}
          onChange={(e) => setProp(props => {
            props.backgroundSetting = {
              ...props.backgroundSetting,
              currentSetting: e.target.value,
            };
          })}
          defaultValue="color"
        >
          <option value="color">Warna</option>
          <option value="image">Gambar</option>
        </Selection>

        {{
          color: (
            <>
              <Box sx={{ fontWeight: 'normal', fontSize: '14px', mt: '15px', mb: '7px' }}>Warna latar belakang</Box>
              <Button variant="outline" sx={{ width: '140px' }} onClick={() => setShowColorPicker(!showColorPicker)}>
                <Flex sx={{ alignItems: 'center' }}>
                  <Box bg={backgroundSetting[backgroundSetting.currentSetting].hex} sx={{ width: '20px', height: '20px', border: '1px solid', borderColor: 'gray1', borderRadius: 's', mr: '8px' }}></Box>
                  <Text>Custom</Text>
                  <FaChevronDown style={{ paddingLeft: '8px' }} size={15} />
                </Flex>
              </Button>
              {showColorPicker && (
                <Box ref={ref}>
                  <SketchPicker width="100%" color={backgroundSetting[backgroundSetting.currentSetting].hex || 'white'} onChange={(event) => setProp(props => {
                    props.backgroundSetting = {
                      ...props.backgroundSetting,
                      [props.backgroundSetting.currentSetting]: {
                        hex: event.hex,
                      }
                    }
                  })} />
                </Box>
              )}
            </>
          ),
          image: (
            <>
              <Box
                sx={{
                  borderRadius: "m",
                  borderColor: "gray0",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  width: '270px',
                  height: '250px',
                  mt: '15px'
                }}
              >
                <Flex
                  py={2}
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    py: "15px",
                    textAlign: "center",
                    height: "250px",
                  }}
                >
                  <Input
                    type="file"
                    ref={fileInputRef}
                    accept="image/png, image/jpg, image/gif"
                    sx={{ display: "none" }}
                    onChange={handleChangeInput}
                  />

                  {backgroundSetting[backgroundSetting.currentSetting].url ? (
                    <Image
                      src={backgroundSetting[backgroundSetting.currentSetting].url}
                      alt="uploaded-image"
                      sx={{ height: "100%" }}
                    />
                  ) : (
                    <>
                      <Text sx={{ pb: "15px" }}>
                        Unggah media jpg, png, atau gif ke sini
                      </Text>

                      <Button variant="outline" onClick={handleClickUpload}>
                        Unggah media
                      </Button>
                    </>
                  )}
                </Flex>
              </Box>

              <Box py="15px">
                {backgroundSetting[backgroundSetting.currentSetting].url && (
                  <Flex mb="15px">
                    <Button
                      variant="outline"
                      sx={{ width: "100%", mr: "5px" }}
                      onClick={handleClickUpload}
                    >
                      Ubah
                    </Button>
                    <Button
                      variant="outline"
                      sx={{ width: "100%", ml: "5px" }}
                      onClick={handleDeleteImg}
                    >
                      Hapus
                    </Button>
                  </Flex>
                )}
              </Box>
            </>
          )
        }[backgroundSetting.currentSetting]}

        <Button
          mt={4}
          sx={{ width: '100%' }}
          onClick={() => {
            setShowColorPicker(false)
            setProp((props) => {
              props.isBackground = false;
              props.isToolbox = true;
            })
          }}
        >
          Selesai
        </Button>
      </Box>
    </Container>
    : isToolbox ?
      <Toolbox /> : null
  )
}

const RootContainerLayer = () => {
  const {
    actions: { setProp },
    backgroundSetting,
    isBackground,
    isToolbox,
  } = useNode((node) => ({
    backgroundSetting: node.data.props.backgroundSetting,
    data: node.data.props.data,
    isBackground: node.data.props.isBackground,
    isToolbox: node.data.props.isToolbox,
  }));
  

  return (
    <Sidebar
      menus={[
        {
          icon: <MdOutlineList size={20} />,
          label: 'Bagian',
          isActive: isToolbox,
          onClick: () => setProp((props) => {
            props.isBackground = false;
            props.isToolbox = true;
          }),
        },
        {
          icon: <MdOutlineImage size={20} />,
          label: 'Latar',
          isActive: isBackground,
          onClick: () => setProp((props) => {
            props.isBackground = true;
            props.isToolbox = false;
          }),
        },
      ]}
    />
  )
}

interface PhoneFrameProps extends BoxProps {
  data?: any
}

export function PhoneFrame({ data, ...props }: PhoneFrameProps) {
  const styleColor = {
    borderRadius: 15,
    // bg: data && data.color || '#ffffff', 
    outline: '10px solid black',
    height: '100%',
    // minHeight: '700px',
    // maxHeight: '700px',
    width: '350px',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    ...props.sx,
  }

  const styleImg = {
    borderRadius: 15,
    // backgroundImage: `url(${data.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    outline: '10px solid black',
    p: 2,
    minHeight: '700px',
    maxHeight: '700px',
    width: '350px',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    ...props.sx,
  }

  return (
    <Box
      {...props}
      // sx={ data && data.type === 'color' ? styleColor : styleImg }
      sx={ styleColor }
    />
  )
}

RootContainer.craft = {
  props: {
    backgroundSetting: {
      color: {
        hex: 'white',
      },
      image: {
        url: null,
      },
      currentSetting: 'color',
    },
    isBackground: false,
    isToolbox: true,
  },
  related: {
    settings: RootContainerSettings,
    layer: RootContainerLayer,
  },
};

export default RootContainer
