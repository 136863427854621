import React, { useState } from "react";
import { useNode } from "@craftjs/core";
import { SketchPicker } from 'react-color';
import { Composition } from 'atomic-layout';
import {
  FaRegSquare,
  FaChevronDown,
} from "react-icons/fa";
import { BsCircle, BsDiamond } from "react-icons/bs";
import { AiOutlineDash } from "react-icons/ai";
import { Box, Container, Flex, Slider, Text } from "@theme-ui/components";
import SelectionSquareComponent from "../Editor/SelectionSquare";
import SharedLayer from "../Editor/SharedLayer";
import { Button } from "theme-ui";
import useOnclickOutside from "react-cool-onclickoutside";

const Divider = ({ color, dividerType, size }) => {
  const {
    connectors: { connect, drag },
    isActive,
    actions: { setProp },
  } = useNode((node) => ({
    isActive: node.events.selected,
  }));

  return (
    <div ref={(ref) => connect(drag(ref))}>
      {
        dividerType === 'line' ?
          <Composition
            width={'100%'}
            inline
            templateCols={`repeat(20, 1fr)`}
            gap={2}
            paddingVertical={4 * size}
          >
            {
              Array.from(Array(20)).map((_, i) => {
                return <Box style={{ background: color, height: '1px', margin: '0 4px' }}></Box>
              })
            }
          </Composition> :
        dividerType === 'lineCircle' ?
          <Flex sx={{ alignItems: 'center', justifyContent: 'center', zIndex: 0, py: `${10 * size}px` }}>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', borderRadius: '50%', width: '5px', height: '5px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
          </Flex> :
        dividerType === 'lineDiamond' ?
          <Flex sx={{ alignItems: 'center', justifyContent: 'center', zIndex: 0, py: `${10 * size}px` }}>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
            <Box style={{ borderColor: color, border: '1px solid', width: '4px', height: '4px', margin: '0 1.5px', transform: 'rotate(-45deg)' }}></Box>
            <Box style={{ background: color, width: '5px', height: '1px', margin: '0 1px' }}></Box>
          </Flex> :
        null
      }
    </div>
  );
};

const DividerSettings = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const closePopper = () => {
    setShowColorPicker(false);
  }

  const ref = useOnclickOutside(() => closePopper())

  const {
    actions: { setProp },
    color,
    dividerType,
    size,
  } = useNode((node) => ({
    color: node.data.props.color,
    dividerType: node.data.props.dividerType,
    size: node.data.props.size,
  }));

  const options = [
    {
      label: "Empty",
      icon: <FaRegSquare size={20} />,
      value: 'empty'
    },
    {
      label: "Line",
      icon: <AiOutlineDash size={20} />,
      value: 'line'
    },
    {
      label: "Line and Circle",
      icon: <BsCircle size={20} />,
      value: 'lineCircle'
    },
    {
      label: "Line and Diamond",
      icon: <BsDiamond size={20} />,
      value: 'lineDiamond'
    },
  ]

  function onChangeSlider(e) {
    setProp((props) => props.size = e.target.value )
  }

  function onSelectDivider(data: any) {
    setProp((props) => props.dividerType = data?.value )
  }

  function onSelectColor(event) {
    setProp((props) => props.color= event.hex ) 
  }

  return (
    <Container>
      <Box>
        <Text sx={{fontWeight: 'bold', mb: '5px'}}>Ukuran</Text>
        <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ flex: 3, mx: '10px' }}>
            <Slider value={size} defaultValue={size || 1} min={1} max={5} onChange={onChangeSlider} />
          </Box>
          <Flex sx={{ flex: 1, bg: 'gray2', borderRadius: 'm', textAlign: 'center', justifyContent: 'center', height: '75px', alignItems: 'center', mx: '10px', maxWidth: '80px' }}>
            <Text>{`${size} X`}</Text>
          </Flex>
        </Flex>
      </Box>

      <Box sx={{ my: '15px' }}>
        <SelectionSquareComponent selectedValue={dividerType} label="Jenis pembatas" description="Pilih jenis pembatas yang diinginkan" options={options} onSelect={onSelectDivider} />
      </Box>

      <Box>
        <Text sx={{fontWeight: 'bold', mb: '5px'}}>Warna</Text>
        <Box>
          <Button variant="outline" sx={{ width: '140px' }} onClick={() => setShowColorPicker(!showColorPicker)}>
            <Flex sx={{ alignItems: 'center' }}>
              <Box bg={color} sx={{ width: '20px', height: '20px', border: '1px solid', borderColor: 'gray1', borderRadius: 's', mr: '8px' }}></Box>
              <Text>Custom</Text>
              <FaChevronDown style={{ paddingLeft: '8px' }} size={15} />
            </Flex>
          </Button>
          {showColorPicker && (
            <Box ref={ref}>
              <SketchPicker width="100%" color={color || 'white'} onChange={onSelectColor} />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

Divider.craft = {
  name: 'Divider',
  props: {
    color: '#000000',
    dividerType: "line",
    size: 1,
  },
  related: {
    settings: DividerSettings,
    layer: SharedLayer,
  },
};

export default Divider;
