import React, { ReactNode } from "react";
import { Button, ButtonProps, Link, } from "theme-ui";
import { FiLink } from 'react-icons/fi'

interface SocialBtnProps extends ButtonProps {
  icon?: ReactNode;
  link?: string;
}

const SocialBtn = ({
  icon = <FiLink size={35} />,
  link = "https://www.w3schools.com",
}:  SocialBtnProps) => {
  return (
    <Link href={link} target="_blank">
      <Button variant="socialBtn">
        {icon}
      </Button>
    </Link>
  );
};

export default SocialBtn;
