import React from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Box, Text, Flex, IconButton } from "theme-ui";

import { InputComponent } from "../Editor/Input";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLine,
  FaYoutube,
  FaTiktok,
  FaTwitterSquare,
  FaWhatsappSquare,
  FaRegTrashAlt,
  FaGripVertical,
} from "react-icons/fa";
import { FiLink } from "react-icons/fi";

import Selection from "../Editor/Selection";
import SocialBtn from "../landingPage/SocialBtn";

import colors from '../../gatsby-plugin-theme-ui/theme/colors'
import ahoy from '../../utils/ahoy/ahoy';


const SocialIcon = ({ url, type }) => {
  const { enabled } = useEditor((state) => {
    return {
      enabled: state.options.enabled,
    }
  });

  const {
    id: nodeId,
  } = useNode((node) => ({
    isActive: node.events.selected,
  }));

  return (
    <Flex
      mx={1}
      my={2}
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
      onClick={() => {
        // Only track events when the editor is not in enabled mode
        // Editor is not enabled when presented to the end user.
        // We need to do this otherwise clicking on the component while the user is editing the page
        // will still trigger an event which will results in inflated count of events.
        if (!enabled) {
          ahoy.default.track(
            'click_social_media_url',
            {
              name: type,
              link_url: url,
            }
          )
        }
      }}
    >
      <SocialBtn
        key={nodeId}
        link={url}
        icon={
          type === "default" ? (
            <FiLink size={20} />
          ) : type === "facebook" ? (
            <FaFacebookSquare size={20} />
          ) : type === "instagram" ? (
            <FaInstagram size={20} />
          ) : type === "line" ? (
            <FaLine size={20} />
          ) : type === "youtube" ? (
            <FaYoutube size={20} />
          ) : type === "tiktok" ? (
            <FaTiktok size={20} />
          ) : type === "twitter" ? (
            <FaTwitterSquare size={20} />
          ) : type === "whatsapp" ? (
            <FaWhatsappSquare size={20} />
          ) : <FiLink size={20} />
        }
      />
    </Flex>
  )
};

const SocialIconSettings = () => {
  const {
    actions: { setProp },
    id: nodeId,
    type,
    url,
  } = useNode((node) => ({
    type: node.data.props.type,
    url: node.data.props.url,
  }));

  return (
    <>
      <Box sx={{ pb: "10px" }}>
        <Selection
          onChange={(event) => {
            setProp((props) => {
              props.type = event.target.value;
            })
          }}
          value={type}
          key={nodeId}
        >
          <option value="default">Default</option>
          <option value="facebook">Facebook</option>
          <option value="instagram">Instagram</option>
          <option value="line">Line</option>
          <option value="youtube">YouTube</option>
          <option value="tiktok">TikTok</option>
          <option value="twitter">Twitter</option>
          <option value="whatsapp">Whatsapp</option>
        </Selection>
      </Box>

      <InputComponent
        key={nodeId}
        placeholder="Masukkan url Anda"
        onChange={(event) => {
          setProp((props) => {
            props.url = event.target.value;
          })
        }}
        value={url}
      />
    </>
  );
};

function SocialIconLayer () {
  const {
    type,
    id: nodeId,
  } = useNode((node) => ({
    type: node.data.props.type,
  }))

  const {
    actions,
    connectors: {
      hover,
      select,
    },
  } = useEditor((state) => {
    return {
      selectedNodeId: state.events.selected,
      settingsComponent:
        state.nodes[state.events.selected] &&
        state.nodes[state.events.selected].related.settings,
    }
  })
  
  return (
    <Flex
      sx={{
        borderRadius: 'm',
        borderStyle: 'solid',
        borderColor: 'gray0',
        borderWidth: 1,
      }}
    >
      <Flex
        sx={{
          flex: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box padding={'7px'} sx={{ flex: 1 }}>
          <IconButton onClick={() => actions.delete(nodeId)} sx={{ cursor: 'pointer' }}>
            <FaRegTrashAlt size={18} color={colors.secondary} />
          </IconButton>
        </Box>
        <Box
          sx={{ flex: 5, textAlign: 'center', cursor: 'pointer' }}
          ref={ref => select(ref, nodeId)}
        >
          <Text color="primary">{iconMap[type]}</Text>
        </Box>
      </Flex>
      <Box ref={ref => hover(ref, nodeId)} padding={'7px'} sx={{ flex: 1, textAlign: 'right' }}>
        <IconButton sx={{ cursor: 'move' }}>
          <FaGripVertical size={18} color={colors.secondary} />
        </IconButton>
        </Box>
    </Flex>
  )
}

SocialIcon.craft = {
  name: 'Ikon Sosial',
  related: {
    settings: SocialIconSettings,
    layer: SocialIconLayer,
  },
};

export default SocialIcon;


const iconMap = {
  default: 'Default',
  facebook: 'Facebook',
  instagram: 'Instagram',
  line: 'Line',
  youtube: 'YouTube',
  tiktok: 'TikTok',
  twitter: 'Twitter',
  whatsapp: 'WhatsApp',
}